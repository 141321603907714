import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "../GeneralTable"
// import AdminParticipantModal from "./forms/AdminParticipantModal"
class ParticipantTicketTable extends React.Component {
  constructor(props) {
    super(props);
    this.renderActions = this.renderActions.bind(this);
  }
  getTableColumns() {
    const { ticketTypes, updateParticipantCount} = this.props;
    const { user } = this.props;
    let allColumns = [
      { title: 'First', field: 'lower_first_name', render: (row) => row.name_first},
      { title: 'Last', field: 'lower_last_name', render: (row) => row.name_last},
      { title: 'Program Name', field: 'attendee_type', render: (row) => this.getAttendeeType(row)},
      { title: 'Status', field: 'status'},
    ]
    // allColumns = allColumns.concat(ticketTypes.map(ticketType => {
    //   // console.log(ticketType);
    //   return {
    //     title: ticketType.name.replace("Day Program |",""),
    //     field: ticketType.slug,
    //     render: (row) => (
    //       <>
    //       {row.metadata.find(meta => meta.field_slug === ticketType.slug) ? this.getTicketCountValue(row.metadata.find(meta => meta.field_slug === ticketType.slug).value) : 0}
    //       </>
    //     )
    //   }
    // }));

    // allColumns = allColumns.concat([
    //   {title: "Actions", sortable: false, field: "", render: this.renderActions}
    // ])
    return allColumns;
  }

  getAttendeeType(row) {
    let programname = row.metadata.find(meta => meta.field_slug === 'programname').value
    let influencer = row.metadata.find(meta => meta.field_slug === 'influencer')
    if (programname === 'press' && influencer !== undefined && influencer.value === 'yes') {
      programname = 'influencer'
    }
    return programname;
  }

  getTicketCountValue(value) {
    if (value === '') {
      return '0'
    }
    return value;
  }

  renderActions(row) {
    const {participants, updateParticipantCallback, ticketTypes, fields, form } = this.props;
    return (
      <>
        {/* <AdminParticipantModal
          participant={row}
          participants={participants}
          ticketTypes={ticketTypes}
          form={form}
          // fields={fields}
          updateParticipantCallback={updateParticipantCallback}
        >
          <a href="#">Edit</a>
        </AdminParticipantModal> */}
      </>
    )
  }

  getTableRegistrants() {
    const { participants } = this.props;
    return participants.map(x=> {
      x.lower_first_name = (x.name_first||"".toLowerCase())
      x.lower_last_name = (x.name_last||"".toLowerCase())
      x.attendee_type = (x.metadata.find(meta => meta.field_slug === "attendeetype") ? x.metadata.find(meta => meta.field_slug === "attendeetype").value : "")
      return x
    }).sort((a,b)=>b.id - a.id);
  }


  render() {
    const { ticketTypes, fields, updateParticipantCallback} = this.props;
    return (
      <div className="sg-tickets-table-container">

        <GeneralTable
          columns={this.getTableColumns()}
          data={this.getTableRegistrants()}
        />
      </div>
    )
  }
}

export default ParticipantTicketTable;
